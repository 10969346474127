:root{
  --white: #fff;
  --blue: #0072D0;
}

@font-face {
  font-family: 'ltsaeada';
  src: url(./fonts/ltsaeada/LTSaeada-Bold.otf);
  font-weight: bold;
  font-style: normal;
}
@font-face{
  font-family: 'neometric';
  src: url(./fonts/neometric/Neometric-Medium.otf);
  font-weight: normal;
  font-style: normal;
}

body{
  height: 100vh;
  max-height: 100vh;
  background-image: url(img/background.png);
  background-size: cover;
  color: var(--white);
  text-align: center;
  font-family: 'neometric';
}

.size{
  position: relative;
}

.input{
  position: absolute;
  background: var(--white);
  padding: 5px 5px 5px 30px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 35px;
  display: flex;
}

.input input{
  background: none;
  border: none;
  font-size: 18px;
  width: 400px;
}

button{
  cursor: pointer;
}

.input input::placeholder{
  opacity: 0.8;
  font-family: 'neometric';
  font-size: 16px;
}

.input button{
  background-color: var(--blue);
  border: none;
  padding: 13px 30px;
  border-radius: 27px;
  color: var(--white);
  font-family: 'neometric';
  font-size: 16px;
  cursor: pointer;
  transition: background 0.4s, color 0.4s;
}

.input button:hover{
  background: var(--white);
  color: var(--blue);
}

img{
  margin-top: 50px;
  width: 60px;
}

h1{
  font-family: 'ltsaeada';
  font-weight: bold;
  font-size: 80px;
  margin-top: 150px;
}

p{
  font-size: 20px;
}

.button button{
  background: none;
  border: none;
  color: var(--white);
  font-size: 16px;
  font-family: 'neometric';
  margin: 0 30px 30px 30px;
}

.highlight{
  border-bottom: 2px solid var(--blue) !important;
}

.socials img{
  width: 25px;
  margin: 350px 5px 0 5px;
}

.padding{
  padding: 0 36% 20px;
}

@media (max-width: 1485px) {
  .padding{
    padding: 0 30% 20px;
  }
}
@media (max-width: 1331px) {
  .input input{
    width: 300px;
  }
}
@media (max-width: 931px) {
  .input input{
    width: 250px;
  }
  .padding{
    padding: 0 23% 20px;
  }
}
@media (max-width: 660px) {
  .padding{
    padding: 0 11% 20px;
  }
  h1{
    font-size: 60px;
  }
}
@media (max-width: 430px) {
  h1{
    font-size: 45px;
  }
  .input input {
    width: 170px;
  }
  .socials img {
    margin: 200px 5px 0 5px;
}
}
@media (max-width: 340px) {
  .input input {
    width: 138px;
  }
  .input button {
    margin-left: 10px;
  }
  .input {
    padding: 5px 5px 5px 10px;
  }
  p {
    font-size: 15px;
  }
  .button button {
    font-size: 15px;
  }
}